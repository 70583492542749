<template>
  <BaseLoad class="white-transparent" />
</template>

<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { nextTick } from 'vue'
import { BaseLoad } from '@/components'

const store = useStore()
const router = useRouter()

// Created
checkAuth()

// Methods
async function checkAuth() {
  const getParams = new URLSearchParams(window.location.search)

  if (getParams.has('data')) {
    const data = atob(getParams.get('data'))
    const token = typeof data === 'string' ? data : JSON.stringify(data)
    localStorage.setItem(`${store.getters.prefix}_token`, token)

    const decodedData = JSON.parse(token)
    store.commit('SET_TOKEN', decodedData)
    await nextTick()
    // setTimeout(() => {
    router.push('/news')
    // }, 500)
  } else {
    if (!localStorage.getItem(`${store.getters.prefix}_token`)) {
      localStorage.removeItem(`${store.getters.prefix}_token`)
      router.replace({ query: [] })
      await nextTick()
      const data = { from: window.location.href.replace(window.location.search, ''), token: null }
      const encodedData = btoa(JSON.stringify(data))
      window.location.replace(process.env.VUE_APP_INTRA + '?data=' + encodedData)
    } else {
      router.push('/news')
    }
  }
}
</script>
